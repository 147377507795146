import React from "react"

import Layout from '../components/layout'
import ContentContain from '../components/contentContain'
import IndexStyles from './index.module.scss'

const AboutPage = () => {
	return (
		<Layout>
			<ContentContain>
				<div className={IndexStyles.indexHero}>
					<h1>I'm a well rounded, 21st Century Jack-of-All trades</h1>
				</div>
				<div className={IndexStyles.indexAbout}>
					<h2>About Me</h2>
					<p>I have been working as a Freelance Creative for over a decade now, I have a wide array of skills that allow mme to bring a unique perspective  to my project</p>
				</div>
				<div className={IndexStyles.indexAbout}>
					<h2>Some of My Work</h2>
					<p>I have been working as a Freelance Creative for over a decade now, I have a wide array of skills that allow mme to bring a unique perspective  to my project</p>
				</div>
			</ContentContain>
		</Layout>
	)
}

export default AboutPage
